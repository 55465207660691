import React, { useEffect, useRef } from "react";
import s from "./Line4.scss";

import gsap from "gsap";
import DrawSVGPlugin from "utils/DrawSVGPlugin.js";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

import { scroll } from "constants/Scroll";

export const Line4 = () => {
  const lineRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      lineRef.current,
      {
        drawSVG: "0%"
      },
      {
        duration: 4.5,
        delay: 2.5,
        drawSVG: "100%",
        ease: "linear",
        scrollTrigger: {
          scroller: scroll.container,
          trigger: lineRef.current,
          scrub: 1,
          start: "top 88%",
          end: "-=300px"
        }
      }
    );
  }, []);

  return (
    <svg
      className={s.line}
      xmlns="http://www.w3.org/2000/svg"
      width="2054"
      height="1198"
      viewBox="0 0 2054 1198"
      fill="none"
    >
      <path
        ref={lineRef}
        d="M0.5 1197C6.66667 1197 47.1 1197 159.5 1197C271.9 1197 302.667 1109.67 304 1066C304 792.167 304 222.3 304 133.5C304 44.7 396.333 8.16667 442.5 1H1405H2053.5"
        stroke="#1B52AD"
        strokeOpacity="0.5"
        strokeWidth="2"
      />
    </svg>
  );
};
